import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Projects = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(e) {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  const serviceContent = [
    {
      id: 1,
      no: "01",
      title: "UMFCCI Member App",
      text: `Membership application for UMFCCI Organization built using Ruby on Rails 2.7.2, Tailwind CSS and PostgreSQL`,
      link: "https://app.umfcci.com.mm"
    },
    {
      id: 2,
      no: "02",
      title: "Win Mobile Website",
      text: `Retail store website for smart products built using Ruby on Rails 2.5.3 and Bootstrap and PostgreSQL`,
      link: "https://eshop.winmobileworld.com"
    },
    {
      id: 3,
      no: "03",
      title: "WanderWise (AirBnb Clone)",
      text: `A full-stack ReactJS based Web application with ExpressJS and MongoDB for backend`,
      link: "https://github.com/PhwayThantChae/airbnb-clone-chat-app"
    },
    {
      id: 4,
      no: "04",
      title: "Heart Failure Prediction With Fairness ML",
      text: `Conducting an analysis of different fairness metrics across multiple classification models using the Heart Failure Prediction dataset from Kaggle, utilizing the FairLearn library for the evaluation.`,
      link: "https://github.com/PhwayThantChae/Heart-Failure-Prediction-With-Fairness-ML"
    },
  ];

  return (
    <>
      <div className="container">
        <div className="tokyo_tm_services">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Projects</span>
                <h3>Project Portfolios</h3>
              </div>
            </div>
          </div>
          {/* End tokyo_tm_title */}

          <div className="list">
            <ul>
              {serviceContent.map((item) => (
                <li key={item.id}>
                  <div className="list_inner">
                    <span className="number">{item.no}</span>
                    <h3 className="title">{item.title}</h3>
                    <p className="text">{item.text}</p>
                    <div className="tokyo_tm_read_more">
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <span>Read More</span>
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* End list */}
        </div>
      </div>
      {/* End .container */}
    </>
  );
};

export default Projects;
