import React from "react";
import Social from "./Social";

const Home = () => {
  return (
    <>
      <div className="tokyo_tm_home">
        <div className="home_content">
          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: "url(assets/img/slider/profile.jpg)",
              }}
            ></div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h3 className="name">Phwe Thant Chay</h3>
            <p className="job">
            Experienced Software Engineer with a four-year background in designing, developing, and implementing software solutions within diverse team environments.
            Passionate about full stack development with substantial hands-on experience in Ruby on Rails, NodeJS, Angular and React. 
            A self-motivated and lifelong learner familiar with data structures, system design and big data technology enthusiast.
            </p>
            {/* END JOB */}
            <Social />
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
