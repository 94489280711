import React from "react";

const KnowledgeInterest = () => {
  const skills_1 = [
    {
      id: 1,
      colClass: "left",
      title: "Languages",
      content: [" Ruby", " JavaScript", " TypeScript", " Python", " Java"],
    },
    {
      id: 2,
      colClass: "right",
      title: "Technologies",
      content: [
        " Ruby on Rails",
        " NodeJS",
        " Express.js",
        " React.js",
        " Angular",
        " Sidekiq",
        " Cron",
        " Git",
        " Docker",
        " Linux",
      ],
    },
  ];

  const skills_2 = [
    {
      id: 1,
      colClass: "left",
      title: "Databases",
      content: [
        " PostgreSQL",
        " MySQL",
        " MongoDB",
        " SQLite",
        " Redis",
        " Cloud Firestore"
      ],
    },
    {
      id: 2,
      colClass: "right",
      title: "Deployment",
      content: [
        " AWS Cloud",
        " Firebase",
        " Heroku",
        " Netlify",
        " Nginx",
        " Apache",
        " Passenger"
      ],
    },
  ];

  return (
    <>
      {skills_1.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_skill_list">
            <ul>
              {item.content.map((val, i) => (
                <li key={i}>
                  <span>
                    <img
                      className="svg"
                      src="assets/img/svg/rightarrow.svg"
                      alt="arrow"
                    />
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      <br/>
      {skills_2.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_skill_list">
            <ul>
              {item.content.map((val, i) => (
                <li key={i}>
                  <span>
                    <img
                      className="svg"
                      src="assets/img/svg/rightarrow.svg"
                      alt="arrow"
                    />
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default KnowledgeInterest;
