import React from "react";

const Education = () => {
  const resumeContent = [
    {
      id: 1,
      colClass: "full-width",
      title: "Education",
      resume: [
        {
          id: 1,
          year: "Sep 2022 - May 2024",
          institute: "Northeastern University",
          degree: "Master of Computer Science",
        },
        {
          id: 2,
          year: "Sep 2013 - Sep 2017",
          institute: "University of Information Technology (UIT)",
          degree: "B.C.Sc (Software Engineering)",
        },
      ],
    },
  ];
  return (
    <>
      {resumeContent.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_resume_list">
            <ul>
              {item?.resume?.map((value) => (
                <li key={value.id}>
                  <div className="list_inner">
                    <div className="time">
                      <span>{value.year}</span>
                    </div>
                    <div className="place">
                      <h3>{value.institute}</h3>
                      <span>{value.degree}</span>
                      <ul>
                        {value.description?.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default Education;