import React from "react";

const Intro = () => {
  const introContent = {
    image: "assets/img/slider/profile.jpg",
    name: "Phwe Thant Chay",
    designation: "Software Engineer",
    text: (
      <>
        <p>
        I am Phwe Thant Chay, a dedicated and results-driven software developer based in Boston, Massachusetts, specializing in backend engineering and web development. With a solid foundation in computer science from Northeastern University and the University of Information Technology, Yangon, I have honed my skills in diverse programming languages and technologies.
        </p>
        <p>
        I am deeply passionate about discovering new and evolving technologies and am dedicated to personal development each day. I relish brainstorming sessions, evaluating suitable technology stacks for various projects, and engaging in discussions with my team to welcome and explore innovative ideas.
        </p>
      </>
    ),
  };

  return (
    <>
      <div className="top_author_image">
        <img src={introContent.image} alt="about" />
      </div>
      <div className="about_title">
        <h3>{introContent.name}</h3>
        <span>{introContent.designation}</span>
      </div>
      <div className="about_text">{introContent.text}</div>
    </>
  );
};

export default Intro;
