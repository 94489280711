import React from "react";

const Resume = () => {
  const resumeContent = [
    {
      id: 1,
      colClass: "full-width",
      title: "Experience",
      resume: [
        {
          id: 1,
          year: "Jun 2023 - Aug 2023",
          institute: "MyExpoPlace",
          degree: "Backend Software Engineer Intern",
          description: [
            "Developed and deployed serverless cloud functions using Firebase for the Node API, enhancing scalability and reducing infrastructure overhead",
            "Implemented pagination for the Firebase Node API, boosting data retrieval by decreasing data load time by 50%",
            "Designed a Firebase chat feature into existing system, enabling real-time communication between users and increasing user engagement by 40%",
            "Constructed and maintained Node.js applications using the Express framework, resulting in a 20% improvement in backend response time and seamless functionality"
          ],
        },
        {
          id: 2,
          year: "Jun 2022 - Dec 2022",
          institute: "Union of Myanmar Federation of Chambers of Commerce",
          degree: "Web Developer",
          description: [
            "Developed Restful API, resulting in a 30% increase in API response time by optimizing query performance",
            "Created an admin panel website for managing announcements, events, members, and posts, resulting in a 50% reduction in manual administrative tasks",
            "Managed server deployment using AWS, including achieving 99.9% uptime and ensuring reliable software",
            "Delivered code optimization strategies, resulting in a 20% improvement in application performance and responsiveness, and supported monthly enhancements to project",
          ],
        },
        {
          id: 3,
          year: "Oct 2017 - Jun 2021",
          institute: "Zwenexsys International Limited",
          degree: "Software Engineer",
          description: [
            "Built Ruby on Rails, Node Web Kit for desktop applications and Angular for front-end, leveraging framework's features to create robust and scalable software, resulting in a 40% reduction in system downtime and improved user experience",
            "Employed Apache, Nginx, and Passenger as deployment tools for Ruby on Rails web applications, ensuring efficient and reliable deployment processes with a 20% reduction in deployment time",
            "Configured, maintained, and deployed servers on AWS for web applications, ensuring a reliable and scalable infrastructure to support efficient software operations, resulting in a 30% improvement in application performance"
          ],
        },
      ],
    },
  ];
  return (
    <>
      {resumeContent.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_resume_list">
            <ul>
              {item?.resume?.map((value) => (
                <li key={value.id}>
                  <div className="list_inner">
                    <div className="time">
                      <span>{value.year}</span>
                    </div>
                    <div className="place">
                      <h3>{value.institute}</h3>
                      <span>{value.degree}</span>
                      <ul>
                        {value.description?.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default Resume;